#bulkhistory .ReactTable {
    border: none ! important;
}

#bulkhistory .ReactTable .rt-thead.-header {
    background: #dae6f3 ! important;
    border-top: 1px solid #b9b6b6;
    border-left: 1px solid #b9b6b6;
    border-right: 1px solid #b9b6b6;
}

#bulkhistory .ReactTable .rt-thead .rt-resizable-header-content {
    color: #222222 ! important;
}

#bulkhistory .ReactTable .rt-tbody {
    border-bottom: 1px solid #b9b6b6;
    border-left: 1px solid #b9b6b6;
    border-right: 1px solid #b9b6b6;
}