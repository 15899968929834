.ReportModalContainer {
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    width: 92%;
    left: -6%;
}

.ReportModalHeader {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 24px 15px 24px;
}

.ReportModalHeaderTxt {
    box-sizing: border-box;
    float: left;
    color: #222222;
    font-weight: 600;
    font-size: 17px;
}

.ReportModalHeaderCloseBtn {
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 20px;
}

.ReportModalBody {
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
}

.ReportModalHRule {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #eaeaea;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ScoreContainer {
    background-color: #ecf0f1;
    width: 100%;
    height: auto;
    border: 1px solid #d4dcde;
    border-radius: 5px;
}

.ScoreHeaderContainer {
    background-color: #36aba6;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.FollowupHeader {
    background-color: #36aba6;
    width: 100%;
    height: auto;
    padding: 15px;
    color: #ffffff;
    border: 1px solid #36aba6;
    font-weight: 600;
    margin-top: 20px;
}

.ScoreBodyContainer {
    padding: 15px;
}

.ResultTxt {
    float: left;
    font-weight: 600;
}

.ResultRightDiv {
    float: right;
}

.ScorePill {
    background-color: #36aba6;
    display: inline-block;
    padding: 7px 14px;
    border-radius: 4px;
    color: #ffffff;
}

.ReportModalFooter {
    background-color: #ecf0f1;
    padding: 20px;
    text-align: right;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.ReportModalCloseBtn {
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    border: 2px solid #dadada;
    margin-left: 5px;
    font-size: 11px;
}

.ReportSectionHeaderContainer {
    background-color: #0E1F35;
    display: block;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.ReportSectionScore {
    background-color: #ffffff;
    color: #2E3D50;
    padding: 5px;
    border-radius: 3px;
    font-weight: 600;
}

.ReportQuestionContainer {
    background-color: #ffffff;
    margin-top: 2px;
    padding: 15px;
}

.ReportQuestionScorePill {
    background-color: #7A8593;
    color: #ffffff;
    font-weight: 600;
    padding: 3px;
    border-radius: 3px;
}

.ReportQuestionIndex {
    color: #222222;
    font-weight: 600;
}

.ReportQuestionText {
    text-align: justify;
    color: #222222;
    font-size: 13px;
    padding-top: 15px;
}

.ReportAnswerLabel {
    color: #222222;
    font-weight: 600;
    padding-top: 20px;
}

.ReportAnswerText {
    color: #222222;
    font-size: 13px;
    text-align: justify;
    padding-top: 5px;
}