#bulk .ReactTable {
    border: none;
}

#bulk .ReactTable .rt-thead.-header {
    background: #F6F9FD ! important;
}

#bulk .ReactTable .rt-thead .rt-resizable-header-content {
    color: #000000 ! important;
    text-transform: uppercase ! important;
    font-size: 10px;
}

#bulk .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: 2px solid #e7e7e7  ! important;
    border-top: 2px solid #e7e7e7  ! important;
    border-bottom: 2px solid #e7e7e7  ! important;
}

#bulk .ReactTable .rt-tbody .rt-td {
    border-right: 2px solid #e7e7e7  ! important;
    border-bottom: 2px solid #e7e7e7  ! important;
}

#bulk .ReactTable .rt-td {
    padding: 0px ! important;
    min-height: 40px;
}

#bulk .pagination-bottom {
    margin-top: 15px;
}