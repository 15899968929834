.questiontabheader {
    padding: 20px;
    background-color: #ffffff;
    font-weight: 600;
    color: #222222;
}
.questionsoutercontainer {
    margin-top: 5px;
    background-color: #ffffff;
    padding: 30px;
}
.sectionpanel {
    border-radius: 5px;
    margin-bottom: 41px;
}
.sectionpanelheader {
    background-color: #04ADA8;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #ffffff;
}
.sectionpanelbody {
    border: 1px solid #F2F2F2;
    padding: 25px;
    box-shadow: 0 0 3px #a7a6a6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.headerleft {
    float: left;
    padding-left: 20px;
    padding-top: 12px;
}
.headerright {
    float: right;
}
.headercenter {
    float: left;
    margin-left: 25%;
}
.addsectionbtn {
    border-radius: 5px;
}
.addsectionright {
    padding-top: 6px;
    padding-right: 13px;
}
.addsectionplus {
    display: inline-block;
    font-size: 24px;
    cursor: pointer;
    margin-top: 3px;
}
.sectioninput {
    background-color: #04ADA8;
    color: #ffffff;
    border: 1px solid #F7F7F7;
    padding: 5px 10px;
    width: 300px;
    border-radius: 3px;
    margin-top: -6px;
    font-family: 'Montserrat', sans-serif;
}
.addQuestionButton {
    display: inline-block;
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    width: 100%;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #37ada7;
}

.questionSection {
    background-color: #F2F2F2;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #222222;
}
.questionSectionBody {
    border: 1px solid #F2F2F2;
    padding: 25px;    
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #F2F2F2;
}
.sectionpanelQuestion {
    border-radius: 5px;
    margin-bottom: 30px;
}
.mgrquestionSelect1 {    
    box-sizing: border-box;
    height: 40px;
    width: 30%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;    
    color: #222222;
    margin-right: 20px;
    padding-left: 10px;
}
.mgrquestionSelect2 {    
    box-sizing: border-box;
    height: 40px;
    width: 30%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;    
    color: #222222;
    padding-left: 10px;
    margin-right: 20px;
}
.mgrquestionSelectSmall {
    box-sizing: border-box;
    height: 28px;
    width: 158px;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 4px #c4c4c4;
    padding-left: 10px;
    margin-top: 10px;
    margin-right: 22px;     
}

.toggle{
  position: relative;
  display: inline-block;
  width: 36px;
  height: 23px;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -7px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #37ada7;
}

input:focus + .slider {
  box-shadow: 0 0 1px #37ada7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
  height: 15px;
    width: 16px;
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  height: 15px;
  width: 16px;
}