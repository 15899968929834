.curveleft {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.curveright {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.ht10 {
    height: 10px;
    float: left;
}