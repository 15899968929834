.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #222222 ! important;
  padding-top: 5px;
}
.react-date-picker__inputGroup__input {
  color: #222222 ! important;
}
.cdate .react-date-picker__inputGroup {
  width: 100% ! important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none ! important;
  padding: 13px 13px ! important;
}
.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  border-radius: 5px ! important;
  height: 30px ! important;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: none ! important;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none ! important;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  border-right: none;
  padding: 10px 13px ! important;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 600 ! important;
  color: #ffffff ! important;
}
.ReactTable .rt-thead.-header {
  background: #1E3E62 ! important;
}

.react-date-picker__inputGroup {
    position: relative;
    width: 160% ! important;
}

.react-date-picker__button {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 1px;
    float: right;
}

.react-date-picker {
    display: inline-flex;
    position: unset ! important;
}

.react-date-picker__calendar .react-calendar {
    border-width: thin;
    position: absolute;
    right: -1px;
    top: 30px;
    width: 350px;
    max-width: 400px;
}

#qc_assign .react-date-picker__calendar .react-calendar {
    border-width: thin;
    position: unset;
    right: 0px;
    top: 0px;
    width: 350px;
    max-width: 400px;
}